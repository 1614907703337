<template>
  <div class="box" v-on:scroll="EventBus.$emit('scroll-card-items', $event.target)">
    <div class="box-header with-border">
      <h3>
        <previous-route-block />

        <label class="btn btn-outline-primary font-scale-2 font-medium"
               v-on:click="$router.push('/tasks/funnel/main')"
               title="Все задачи">
          Все задачи
          <transition name="fade">
            <small v-if="tasks_statistic && tasks_statistic.main > 0" class="text-aqua text-bold">
              ({{ tasks_statistic.main }})
            </small>
          </transition>
        </label>
        <label class="btn btn-outline-primary font-scale-2 font-medium"
               v-on:click="$router.push('/tasks/funnel/personal')"
               title="Личные задачи">
          Личные
          <transition name="fade">
            <small v-if="tasks_statistic && tasks_statistic.personal > 0" class="text-aqua text-bold">
              ({{ tasks_statistic.personal }})
            </small>
          </transition>
        </label>
        <label class="btn btn-primary font-scale-2 font-medium">
          Филиалы
          <transition name="fade">
            <small v-if="tasks_statistic" class="text-white font-light">
              ({{ tasks_statistic.personal_branch }})
            </small>
          </transition>
        </label>

        <button type="button"
                class="btn btn-success pull-right"
                v-on:click="EventBus.$emit('open-create-quick-task-modal')"
                title="Добавить задачу">
          <i class="fa fa-plus"></i>
        </button>
      </h3>
    </div>

    <funnel-tasks :task_type_id="102" :show_task_type_statuses="false" />
  </div>
</template>

<script>
import Vue from "vue";

Vue.component("funnel-tasks", (resolve) => {
  return require(["./../index.vue"], resolve);
});

export default {
  name: "funnel-personal-branch",

  metaInfo() {
    return {
      title: this.page_title,
    };
  },

  data: () => ({
    page_title: "Задачи филиала",

    tasks_statistic: null,
  }),

  async created() {
    try {
      this.EventBus.$on("set-tasks-statistic", tasks_statistic_data => {
        this.tasks_statistic = tasks_statistic_data;
      });
    } catch (error) {
      errorHandler(error);
    }
  },
};
</script>

<style scoped>

</style>
